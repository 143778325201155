import { mapActions } from 'vuex'

export default {
  name: 'NotionIntegrations',
  data () {
    return {
      isLoading: true
    }
  },
  methods: {
    ...mapActions([
      'postNotionIntegration'
    ])
  },
  async mounted () {
    this.isLoading = true
    try {
      const { code, state } = this.$route.query
      const contentplanId = decodeURIComponent(state).split('=')[1]
      console.log('code:', code, 'state:', state)

      if (code) {
        await this.postNotionIntegration({
          contentplanId,
          code,
          redirectUri: window.location.origin + window.location.pathname
        })
        this.isLoading = false
      }
    } catch (error) {
      console.error(error)
    }
  }
}

import { mapGetters, mapMutations, mapActions } from 'vuex'

import Sidebar from '@/components/Sidebar/Sidebar.vue'

export default {
  name: 'App',
  components: {
    Sidebar
  },
  data () {
    return {
      isLoading: {
        workspaces: false
      },
      sideBarModel: true,
      status: null,
      statusRecheckInterval: null
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'showSidebar',
      'workspaces',
      'activeWorkspace',
      'contentplans',
      'activeContentplan',
      'snackbar'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      if (this.activeContentplan) {
        return this.activeContentplan.name
      }
    },
    hideNav () {
      return this.$route.name === 'Clusterer' || this.$route.name === 'RoiCalculator'
    },
    trialCountdown () {
      if (!this.activeWorkspace || !this.activeWorkspace.free_trial) return
      const now = new Date()
      let endDate = new Date()

      if (this.activeWorkspace.trial_extended_at) {
        const trialExtensionDays = 7
        const startDate = new Date(this.activeWorkspace.trial_extended_at)
        endDate = new Date(startDate.setDate(startDate.getDate() + trialExtensionDays))
      } else {
        const trialDays = 3
        const startDate = new Date(this.activeWorkspace.created_at)
        endDate = new Date(startDate.setDate(startDate.getDate() + trialDays))
      }

      if (now < endDate) {
        return Math.floor((endDate - now) / (1000 * 60 * 60 * 24))
      }

      return 0
    },
    showBanner () {
      return this.activeWorkspace && this.activeWorkspace.subscription_active === 0 && this.activeWorkspace.type !== 'saas'
    }
  },
  methods: {
    ...mapActions([
      'loadWorkspaces',
      'loadContentplans',
      'loadContentplan'
    ]),
    ...mapMutations([
      'updateActiveWorkspace',
      'updateActiveContentplan',
      'setSidebar'
    ]),
    async loadData () {
      // In these views don't load anything
      if (this.$route.name === 'Clusterer') return
      if (this.$route.name === 'LoginManaged') return
      if (this.$route.name === 'sharedArticle' || this.$route.name === 'sharedArticleLegacy' || this.$route.name === 'DirectorShared') return
      if (this.$route.name === 'Login' || this.$route.name === 'Auth') return
      if (this.$route.name === 'CreateContentplan' || this.$route.name === 'CreateContentplanStep2') return
      if (this.$route.name === 'RoiCalculator') return
      if (this.$route.name === 'KeywordResearch') return
      if (this.$route.name === 'StripeLander') return
      if (this.$route.name === 'NotionIntegration') return
      this.isLoading.workspaces = true

      // If workspaces are not loaded, load them
      if (!this.workspaces) {
        console.log('Loading workspaces')
        try {
          await this.loadWorkspaces()
        } catch (e) {
          console.log('Error loading workspaces', e)
        }
      }

      // If no workspaces are returned, then user is logged out. Redirect to login page
      if (!this.workspaces?.length) {
        console.log('No workspaces, redirecting to login')
        this.$router.push('/auth/out')
        return
      }

      // If no workspaceId is in the route or if workspaceId is in the route but doesn't match any available workspace, redirect to first workspace
      if (!this.workspaceId || !this.workspaces.find(w => w.id === this.workspaceId)) {
        console.log('No workspaceId in route, redirecting to first workspace')
        // Prefer managed workspace if available
        if (this.workspaces.find(w => w.type === 'managed')) {
          this.$router.push({
            name: 'Workspace',
            params: {
              workspaceId: this.workspaces.find(w => w.type === 'managed').id
            }
          })
        } else {
          this.$router.push({
            name: 'Workspace',
            params: {
              workspaceId: this.workspaces[0].id
            }
          })
        }
        return
      }

      // If workspaceId is in the route, but no active workspace is set, set active workspace and get contentplans
      if (this.workspaceId && !this.activeWorkspace) {
        console.log('WorkspaceId in route, setting active workspace and getting contentplans')
        try {
          const workspace = this.workspaces.find(w => w.id === this.workspaceId)
          await this.updateActiveWorkspace(workspace)
          if (this.activeWorkspace?.type === 'saas') {
            if (this.$route.name !== 'PaidClusterer') {
              this.$router.push({
                name: 'PaidClusterer',
                params: {
                  workspaceId: this.workspaceId
                }
              })
            }
            return
          } else {
            await this.loadContentplans({
              workspaceId: this.workspaceId
            })
          }
        } catch (e) {
          console.log('Error loading contentplans', e)
        }
      }

      // If its a saas workspace, stop here
      if (this.activeWorkspace?.type === 'saas') {
        this.isLoading.workspaces = false
        return
      }

      // If workspace subscription has expired and route is not workspace view, redirect to workspace view
      if (this.activeWorkspace?.free_trial && !this.trialCountdown) {
        console.log('Workspace has expired, redirecting to workspace view')
        if (this.$route.name !== 'Workspace') {
          this.$router.push({
            name: 'Workspace',
            params: {
              workspaceId: this.workspaceId
            }
          })
        }
        return
      }

      // If no contentplans are returned, redirect to create contentplan page
      if (!this.contentplans?.length) {
        console.log('No contentplans, redirecting to create contentplan')
        this.$router.push({
          name: 'CreateContentplan',
          params: {
            workspaceId: this.workspaceId
          }
        })
        return
      }

      // If only one contentplan is returned AND it has no domain, redirect to create contentplan page (2nd step)
      if (this.contentplans.length === 1 && !this.contentplans[0].domain) {
        console.log('Only one contentplan with no domain, redirecting to create contentplan step 2')
        this.$router.push({
          name: 'CreateContentplanStep2',
          params: {
            workspaceId: this.workspaceId,
            contentplanId: this.contentplans[0].id
          }
        })
        return
      }

      // If no contentplan in the route, redirect to the first contentplan.
      if (!this.contentplanId && this.contentplans?.length) {
        console.log('No contentplanId in route, redirecting to first contentplan')
        this.$router.push({
          name: 'Home',
          params: {
            workspaceId: this.workspaceId,
            contentplanId: this.contentplans[0].id
          }
        })
        return
      }

      // If contentplan in route, then set it as active contentplan
      if (this.contentplanId) {
        console.log('ContentplanId in route, setting active contentplan')
        const contentplan = this.contentplans.find(c => c.id === this.contentplanId)
        this.updateActiveContentplan(contentplan)
      }

      // If activeContentplan is crawling or grouping, show status bar
      this.calculateStatus(this.activeContentplan)
      if (this.status) {
        this.statusRecheckInterval = setInterval(this.recheckStatus, 10000)
      }

      this.isLoading.workspaces = false
    },
    openSidebar () {
      this.sideBarModel = true
      this.setSidebar(true)
    },
    async calculateStatus (contentplan) {
      if (!contentplan) {
        this.status = null
      } else if (contentplan.import_started && !contentplan.import_ended) {
        this.status = 'importing'
      } else if (contentplan.crawl_started && !contentplan.crawl_ended) {
        this.status = 'crawling'
      } else if (contentplan.grouping_started && !contentplan.grouping_ended) {
        this.status = 'grouping'
      } else if (contentplan.alerts_started && !contentplan.alerts_ended) {
        this.status = 'alerts'
      } else {
        this.status = null
      }
    },
    async recheckStatus () {
      console.log('Rechecking status')
      try {
        const contentplanData = await this.loadContentplan({
          contentplanId: this.contentplanId
        })
        this.calculateStatus(contentplanData)
      } catch (e) {
        console.log('Error rechecking status', e)
      }

      if (!this.status) {
        clearInterval(this.statusRecheckInterval)
      }
    }
  },
  async mounted () {
    await this.loadData()
  }
}

import { mapGetters, mapActions, mapMutations } from 'vuex'

import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'

export default {
  name: 'Map',
  data () {
    return {
      isLoading: {
        addItem: false,
        addHub: false,
        renameTopic: false,
        removeItem: false,
        deleteTopic: false,
        writeAll: false
      },

      newItem: null,

      editMode: false,
      topicNewName: '',
      funnels: ['top', 'middle', 'bottom'],
      mappedArticles: [],
      autocompleteArticles: [],
      timeout: null,
      searchArticle: null,
      chipColors: [
        { id: 'leave', name: 'Leave as-is', color: 'green lighten-4' },
        { id: 'update', name: 'Update', color: 'orange lighten-4' },
        { id: 'new', name: 'New', color: 'gray lighten-4' },
        { id: 'in_progress', name: 'In progress', color: 'blue lighten-4' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'topics',
      'articles',
      'activeContentplan'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan?.name ?? ''
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadArticles',
      'loadTopics',
      'updateArticles',
      'addTopic',
      'updateTopics',
      'initiateCreateContent'
    ]),

    ...mapMutations([
      'topicRename'
    ]),

    // RENDER DATA
    async loadData () {
      try {
        this.isLoading.articles = true
        document.title = 'Funnel map | ContentGecko'

        await Promise.all([
          this.loadArticles({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filterHasTopic: true,
            filterHasFunnel: true
          }),
          this.loadTopics({
            contentplanId: this.contentplanId
          })
        ])
        this.mappedArticles = this.articles
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading.articles = false
      }
    },
    async addItem (parentItem, newItem, funnel) {
      this.isLoading.addItem = true
      const articleIds = [newItem]
      const topicId = parentItem.id

      try {
        await Promise.all([
          this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'updateTopic',
            articleIds,
            topicId
          }),
          this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'updateFunnel',
            articleIds,
            funnel
          }),
          this.updateArticles({
            contentplanId: this.contentplanId,
            action: 'addToMap',
            articleIds
          })
        ])
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.addItem = false
      this.showAddItem = false
      this.newItem = null
    },
    async removeItem (itemToRemove) {
      const articleIds = [itemToRemove.article_id]
      this.isLoading.removeItem = itemToRemove.article_id
      console.log('removeItem', articleIds)

      try {
        await this.updateArticles({
          contentplanId: this.contentplanId,
          action: 'removeFromMap',
          articleIds
        })
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.removeItem = false
    },
    toggleEdit (topic) {
      console.log('toggleEdit', topic)

      if (this.showEdit === topic.id) {
        this.showEdit = null
      } else {
        this.showEdit = topic.id
      }
    },
    getTopicArticles (topic, funnel) {
      if (!this.articles?.length) return
      const topicId = topic?.id

      return this.articles.filter(a => a.funnel === funnel && a.topic_id === topicId && a.show_in_map)
    },
    async addHub () {
      console.log('addHub')
      this.isLoading.addHub = true

      try {
        await this.addTopic({
          contentplanId: this.contentplanId,
          name: 'New hub'
        })
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.addHub = false
    },
    async deleteTopic (topicId) {
      if (!topicId) return
      this.isLoading.deleteTopic = topicId

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds: topicId,
          action: 'delete'
        })
        await this.loadData()
      } catch (error) {
        console.log(error)
      }

      this.isLoading.deleteTopic = false
    },
    async renameTopic (topicId) {
      if (!this.topicNewName) return

      this.isLoading.renameTopic = true
      const topicIds = topicId
      if (!topicIds) return

      try {
        await this.updateTopics({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          topicIds,
          name: this.topicNewName,
          action: 'rename'
        })
        this.topicRename({
          topicId: topicIds,
          name: this.topicNewName
        })

        this.showTopicRename = false
        this.topicNewName = null
        this.isLoading.renameTopic = false
      } catch (e) {
        console.log(e)
      }
    },
    getChipColor (article) {
      // PLANNED - Purple if article has deadline and status is not published
      if (article.deadline && article.status !== 'published') return this.chipColors.find(c => c.id === 'in_progress').color
      // NEW PAGE - Blue if article has no url and is not empty
      if (!article.article_url && !article.empty) return this.chipColors.find(c => c.id === 'new').color
      // EXISTING PAGE
      if (article.article_url && !article.empty) {
        const action = article.action || article.suggested_action
        if (action === 'leave') return this.chipColors.find(c => c.id === 'leave').color
        return this.chipColors.find(c => c.id === 'update').color
      }
    },
    async loadAutocompleteArticles (searchString) {
      clearTimeout(this.timeout) // Clear previous timeout
      this.timeout = setTimeout(async () => {
        if (!this.searchArticle) return

        this.isLoading.addItem = true

        try {
          this.autocompleteArticles = await this.loadArticles({
            workspaceId: this.workspaceId,
            contentplanId: this.contentplanId,
            filterTitle: searchString,
            filterNotMapped: true,
            straightReturn: true
          })
        } catch (error) {
          console.log(error)
        }

        this.isLoading.addItem = false
      }, 500)
    },
    async handleWriteAll () {
      this.isLoading.writeAll = true

      try {
        await this.initiateCreateContent({
          contentplanId: this.contentplanId,
          selectBy: 'all',
          skipPublish: true
        })
      } catch (error) {
        console.log(error)
      }

      this.isLoading.writeAll = false
    }
  },
  async mounted () {
    await this.loadData()
  }
}

import { mapGetters, mapActions } from 'vuex'
import parseData from '@/utils/parseData'
import tooltips from '@/utils/tooltips'

import KPI from '@/components/KPI/KPI.vue'

export default {
  name: 'Home',
  data () {
    return {
      isLoading: {
        gsc: false,
        pages: false,
        stats: false
      },

      tableTrendingClusters: {
        isLoading: false,
        headers: [
          { text: 'URL', value: 'article_url' },
          { text: 'Cluster', value: 'title' },
          { text: 'Impressions', value: 'page_impressions_last' },
          { text: 'Impressions Change', value: 'page_impressions_change' }
        ],
        sortBy: 'page_impressions_change',
        sortDesc: true
      },

      tasksDue: [],
      clicksLast: null,
      clicksPrev: null,
      imprLast: null,
      imprPrev: null,
      nrOfKeywordsLast: null,
      nrOfKeywordsPrev: null,
      topTrendingPages: [],
      topDecliningPages: [],
      statsTotalPages: null,
      statsPublishedPages: null,
      statsIndexedPages: null
    }
  },
  watch: {
    async $route () {
      await this.loadData()
    }
  },
  computed: {
    ...mapGetters([
      'articles',
      'activeContentplan',
      'gsc',
      'pages',
      'keywords'
    ]),
    workspaceId () {
      return parseInt(this.$route.params.workspaceId)
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    contentplanName () {
      return this.activeContentplan ? this.activeContentplan.name : ''
    },
    clicksChange () {
      if (!this.clicksLast || !this.clicksPrev) return 0
      const changePercentage = parseData(((this.clicksLast - this.clicksPrev) / this.clicksPrev) * 100)
      return Math.round(changePercentage)
    },
    imprChange () {
      if (!this.imprLast || !this.imprPrev) return 0
      const changePercentage = parseData(((this.imprLast - this.imprPrev) / this.imprPrev) * 100)
      return Math.round(changePercentage)
    },
    nrOfKeywordsChange () {
      if (!this.nrOfKeywordsLast || !this.nrOfKeywordsPrev) return 0
      const changePercentage = parseData(((this.nrOfKeywordsLast - this.nrOfKeywordsPrev) / this.nrOfKeywordsPrev) * 100)
      return Math.round(changePercentage)
    }
  },
  methods: {
    parseData,
    tooltips,
    ...mapActions([
      'loadArticles',
      'loadArticlesAdvanced',
      'getGSCDataAndReturn',
      'getGSCDataByDate',
      'getGSCDataByQuery',
      'getGSCDataByPage',
      'getIntegrations'
    ]),
    async loadData () {
      this.isLoading.gsc = true
      document.title = 'Home | ContentGecko'

      try {
        await Promise.all([
          this.populateClicksAndImpressions(),
          this.populateKeywordCount(),
          this.populateTasksDue(),
          this.getIntegrations({ contentplanId: this.contentplanId }),
          this.populatePageTables(),
          this.populateStats()
        ])
      } catch (error) {
        console.error('Error loading data:', error)
      }

      this.isLoading.gsc = false
    },
    async populatePageTables () {
      try {
        this.isLoading.pages = true

        const [dataTrending, dataDeclining] = await Promise.all([
          this.loadArticlesAdvanced({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filter_existing: true,
            offset: 0,
            limit: 5,
            orderByColumn: 'page_impressions_change',
            orderByDirection: 'desc',
            straightReturn: true
          }),
          this.loadArticlesAdvanced({
            contentplanId: this.contentplanId,
            filter_contentplanId: this.contentplanId,
            filter_existing: true,
            offset: 0,
            limit: 5,
            orderByColumn: 'page_impressions_change',
            orderByDirection: 'asc',
            straightReturn: true
          })
        ])

        this.topTrendingPages = dataTrending?.articles
        this.topDecliningPages = dataDeclining?.articles
        this.isLoading.pages = false
      } catch (error) {
        console.error('Error populating page tables:', error)
      }
    },
    async populateStats () {
      this.isLoading.stats = true
      try {
        const data = await this.loadArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          filterHasFinal: true,
          filterIsMapped: true,
          straightReturn: true
        })
        if (!data) return
        this.statsTotalPages = data.length
        this.statsPublishedPages = data.filter(article => article.status === 'published')?.length
        this.statsIndexedPages = data.filter(article => article.page_impressions_last > 0)?.length
      } catch (error) {
        console.error('Error populating stats:', error)
      }
      this.isLoading.stats = false
    },
    async populateClicksAndImpressions () {
      const TIMEFRAME = 30
      const DAYS = TIMEFRAME * 2
      const OFFSET = 0

      try {
        const [startDate, endDate] = this.setDates(DAYS, OFFSET)

        await this.getGSCDataByDate({ contentplanId: this.contentplanId, startDate, endDate, aggregation: 'daily', country: 'all' })

        const dataTypes = ['clicks', 'impressions']
        const [clicksData, imprData] = dataTypes.map(this.aggregateData)

        this.clicksPrev = clicksData[0]
        this.clicksLast = clicksData[1]
        this.imprPrev = imprData[0]
        this.imprLast = imprData[1]
      } catch (error) {
        console.error('Error populating clicks and impressions:', error)
      }
    },
    async populateKeywordCount () {
      const TIMEFRAME = 30
      const OFFSET = 0

      try {
        // Set dates for last period and previous period
        const [startDate1, endDate1] = this.setDates(TIMEFRAME, OFFSET)
        const [startDate2, endDate2] = this.setDates(TIMEFRAME * 2, TIMEFRAME)

        // Get data for last period and previous period simultaneously
        const [dataLast, dataPrev] = await Promise.all([
          this.getGSCDataAndReturn({
            contentplanId: this.contentplanId,
            startDate: startDate1,
            endDate: endDate1,
            country: 'all',
            dimensions: 'QUERY'
          }),
          this.getGSCDataAndReturn({
            contentplanId: this.contentplanId,
            startDate: startDate2,
            endDate: endDate2,
            country: 'all',
            dimensions: 'QUERY'
          })
        ])

        this.nrOfKeywordsLast = dataLast.length
        this.nrOfKeywordsPrev = dataPrev.length
      } catch (error) {
        console.error('Error populating keyword count:', error)
      }
    },
    async populateTasksDue () {
      try {
        const data = await this.loadArticles({
          workspaceId: this.workspaceId,
          contentplanId: this.contentplanId,
          filterHasDeadline: true,
          straightReturn: true
        })

        this.tasksDue = this.articlesDueThisMonth(data) || []
      } catch (error) {
        console.error('Error populating tasks due:', error)
      }
    },
    aggregateData (dataType) {
      if (!this.gsc || !this.gsc.byDate) return [0, 0]

      let firstSum, secondSum
      try {
        const { byDate } = this.gsc
        const half = Math.ceil(byDate.length / 2)
        const [firstHalf, secondHalf] = [byDate.slice(0, half), byDate.slice(half)]

        const aggregate = (data, type) => data.reduce((total, item) => total + item[type], 0)

        firstSum = aggregate(firstHalf, dataType)
        secondSum = aggregate(secondHalf, dataType)
      } catch (error) {
        console.error('Error aggregating data:', error)
      }
      return [firstSum, secondSum]
    },
    articlesDueThisMonth (articles) {
      const today = new Date()
      const firstDay = new Date(today.getFullYear(), today.getMonth(), 1)
      const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, 1)
      const filteredArticles = articles.filter(article => article.deadline && new Date(article.deadline) > firstDay && new Date(article.deadline) < nextMonth)
      return filteredArticles || 0
    },
    setDates (startDayOffset, endDayOffset) {
      const GSC_MIN_OFFSET = 3
      const today = new Date()

      const startDate = new Date()
      startDate.setDate(today.getDate() - startDayOffset - GSC_MIN_OFFSET + 1)

      const endDate = new Date()
      endDate.setDate(today.getDate() - endDayOffset - GSC_MIN_OFFSET)

      return [this.formatDate(startDate), this.formatDate(endDate)]
    },
    formatDate (date) {
      const year = date.getFullYear()
      // JavaScript months are 0-based, so +1 is added to get the correct month number
      const month = ('0' + (date.getMonth() + 1)).slice(-2)
      const day = ('0' + date.getDate()).slice(-2)
      return `${year}-${month}-${day}`
    }
  },
  async mounted () {
    await this.loadData()
  },
  components: {
    KPI
  }
}

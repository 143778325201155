import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'WordpressIntegrations',
  data () {
    return {
      websiteUrl: '',
      rules: {
        validUrl: value => {
          const pattern = /^(http(s):\/\/.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/
          return pattern.test(value) || 'Please enter a valid URL.'
        }
      },
      integrations: []
    }
  },
  methods: {
    ...mapActions([
      'getIntegrations',
      'postWordpressIntegration'
    ])
  },
  computed: {
    ...mapGetters(['activeContentplan', 'activeIntegration']),
    authUrl () {
      const url = window.location.href
      return `${this.websiteUrl}/wp-admin/authorize-application.php?app_name=contentgecko_${this.contentplanId}&success_url=${url}`
    },
    isUrlValid () {
      return this.rules.validUrl(this.websiteUrl) === true
    },
    contentplanId () {
      return parseInt(this.$route.params.contentplanId)
    },
    activeIntegrationDomain () {
      return this.activeIntegration?.wp_domain
    }
  },
  async mounted () {
    try {
      const { query } = this.$route

      if (query.user_login && query.site_url && query.password) {
        await this.postWordpressIntegration({
          wpUser: query.user_login,
          wpDomain: query.site_url,
          password: query.password,
          contentplanId: this.contentplanId,
          type: 'wp'
        })
      }

      this.getIntegrations({ contentplanId: this.contentplanId })
    } catch (error) {
      console.error(error)
    }
  }
}

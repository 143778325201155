import axios from 'axios'

// HELPERS
const createParamsString = (data) => {
  let params = '?'
  for (const key in data) {
    params += `${key}=${encodeURIComponent(data[key])}&`
  }
  return params.slice(0, -1)
}

const setSnackbar = async ({ commit }, data) => {
  commit('setSnackbar', data)
}

const makeAxiosRequest = async ({ getters, commit, urlSlug, method, data, displaySnackbar = true }) => {
  try {
    const { data: response } = await axios({
      method,
      url: `${getters.apiUrl}/${urlSlug}`,
      data,
      ...getters.apiHeader
    })

    return response
  } catch (error) {
    const errorMessage = error?.response?.data?.message || error.error || error.message
    if (displaySnackbar) setSnackbar({ commit }, { open: true, text: errorMessage, type: 'error' })
    throw error
  }
}

// Internal Links
const loadInternalLinksByArticle = async ({ getters, commit }, data) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/internal-links/article/${data.articleId}?key=${data.sharingKey || ''}`, getters.apiHeader)
  await commit('updateInternalLinks', response)
}

const loadInternalLinksByContentplan = async ({ getters, commit }, data) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/internal-links/contentplan/${data.contentplanId}${createParamsString(data)}`, getters.apiHeader)
  await commit('updateInternalLinks', response)
}

// Free Clusterer
const startClusterer = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: 'clusterer',
    method: 'post',
    data
  })

  return response
}

const getClustererStatus = async ({ getters }, data) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/clusterer?contentplanId=${data.contentplanId}`, getters.apiHeader)
  return response
}

const clustererSendEmail = async ({ getters }, data) => {
  const response = await axios.post(`${getters.apiUrl}/clusterer/email`, data, getters.apiHeader)
  return response
}

// GSC integration
const getGSCDataAndReturn = async ({ getters }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
  return response
}

const getGSCDataByDate = async ({ getters, commit }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
  commit('updateGSCDataByDate', response)
}

const getGSCDataByQuery = async ({ getters, commit }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
  commit('updateGSCDataByQuery', response)
}

const getGSCDataByPage = async ({ getters, commit }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/gsc`, data, getters.apiHeader)
  commit('updateGSCDataByPage', response)
}

// Clustering visualisation
const postHierarchicalClustering = async ({ getters }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/hierarchical-clustering`, data, getters.apiHeader)
  return response
}

// Workspaces
const loadWorkspaces = async ({ getters, commit }) => {
  const { data: workspaces } = await axios.get(`${getters.apiUrl}/workspace`, getters.apiHeader)
  await commit('updateWorkspaces', workspaces)
}

const updateWorkspace = async ({ getters }, data) => {
  const { data: response } = await axios.put(`${getters.apiUrl}/workspace/${data.workspaceId}`, data, getters.apiHeader)
  return response
}

// Contentplans
const loadContentplans = async ({ getters, commit }, data) => {
  const { data: contentplans } = await axios.get(`${getters.apiUrl}/workspace/${data.workspaceId}/contentplan`, getters.apiHeader)
  await commit('updateContentplans', contentplans)
}

const createContentplan = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: `workspace/${data.workspaceId}/contentplan`,
    method: 'post',
    data
  })
  return response
}

const loadContentplan = async ({ getters, commit }, data) => {
  const { data: contentplan } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}`, getters.apiHeader)
  await commit('updateActiveContentplan', contentplan)
  return contentplan
}

const updateContentplan = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/workspace/${data.workspaceId}/contentplan`, data, getters.apiHeader)
}

const resetContentplan = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: `contentplan/${data.contentplanId}/reset-clusters`,
    method: 'post',
    data
  })
  return response
}

// Keywords
const loadContentplanKeywords = async ({ getters, commit }, data) => {
  const { data: keywords } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword${createParamsString(data)}`, getters.apiHeader)
  await commit('updateContentplanKeywords', keywords)
}

const loadPageKeywords = async ({ getters, commit }, data) => {
  const { data: keywords } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword/page?pageId=${data.pageId || ''}&key=${data.sharingKey || ''}`, getters.apiHeader)
  await commit('updatePageKeywords', keywords)
}

const loadArticleKeywords = async ({ getters, commit }, data) => {
  const { data: keywords } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/keyword?key=${data.sharingKey || ''}`, getters.apiHeader)
  if (data.straightReturn) return keywords
  await commit('updateArticleKeywords', keywords)
}

const getKeyword = async ({ getters }, data) => {
  const { data: keyword } = await axios.get(`${getters.apiUrl}/keyword?keyword=${data.keyword}&contentplanId=${data.contentplanId}`, getters.apiHeader)
  return keyword
}

const getMostPopularKeywords = async ({ getters }, data) => {
  const { data: keywords } = await axios.get(`${getters.apiUrl}/keyword/popular${createParamsString(data)}`, getters.apiHeader)
  return keywords
}

const getRelatedKeywords = async ({ getters }, data) => {
  const { data: keywords } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword/related`, data.keywords, getters.apiHeader)
  return keywords
}

const getRelatedKeywordsNlp = async ({ getters }, data) => {
  const { data: keywords } = await axios.post(`${getters.apiUrl}/keyword/related/nlp`, data, getters.apiHeader)
  return keywords
}

const getRelatedBySerpOverlap = async ({ getters }, data) => {
  const { data: keywords } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword/related${createParamsString(data)}`, getters.apiHeader)
  return keywords
}

const uploadKeywords = async ({ getters }, data) => {
  await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, data, getters.apiHeader)
}

const moveKeywords = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, { keywordIds: data.keywordIds, articleId: data.articleId }, getters.apiHeader)
}

const excludeKeywords = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, { keywordIds: data.keywordIds, excluded: data.excluded }, getters.apiHeader)
}

const queueKeywords = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, { keywordIds: data.keywordIds, queue: data.queue, queueAll: data.queueAll || false }, getters.apiHeader)
}

const queueAllKeywords = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, { queueAll: true }, getters.apiHeader)
}

const updateKeywords = async ({ getters }, data) => {
  const { data: response } = await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/keyword`, data, getters.apiHeader)
  return response
}

const getKeywordsByKeyword = async ({ getters }, data) => {
  const { data: keywords } = await axios.get(`${getters.apiUrl}/keyword/${data.keywordId}/keyword?articleId=${data.articleId || ''}`, getters.apiHeader)
  return keywords
}

const processKeywords = async ({ getters }, data) => {
  const { data: keywords } = await axios.post(`${getters.apiUrl}/keyword/process`, data, getters.apiHeader)
  return keywords
}

// Pages
const getPage = async ({ getters }, data) => {
  const { data: page } = await axios.get(`${getters.apiUrl}/page?page=${data.page}&contentplanId=${data.contentplanId}`, getters.apiHeader)
  return page
}

const getPageFromS3 = async ({ getters }, data) => {
  const { data: page } = await axios.get(`${getters.apiUrl}/page/s3?pageId=${data.pageId}`, getters.apiHeader)
  return page
}

const updatePages = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/page`, { pageIds: data.pageIds, excluded: data.excluded, action: data.action, articleIds: data.articleIds, customUrl: data.customUrl, lock: data.lock, mergeWith: data.mergeWith }, getters.apiHeader)
}

const analyzeLandingPage = async ({ getters }, data) => {
  await axios.get(`${getters.apiUrl}/page/${data.pageId}/analyze`, getters.apiHeader)
}

const loadPagesByContentplan = async ({ getters, commit }, data) => {
  const { data: pages } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/page${createParamsString(data)}`, getters.apiHeader)
  commit('updatePages', pages)
}

const loadPagesByArticle = async ({ getters, commit }, data) => {
  const { data: pages } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/page?key=${data.sharingKey || ''}`, getters.apiHeader)
  commit('updatePages', pages)
}

const loadPagesByKeywords = async ({ getters, commit }, data) => {
  const { data: pages } = await axios.get(`${getters.apiUrl}/page/keywords${createParamsString(data)}`, getters.apiHeader)
  commit('updatePages', pages)
}

// Topics
const addTopic = async ({ getters }, data) => {
  const { data: id } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/topic`, data, getters.apiHeader)
  return id
}

const loadTopics = async ({ getters, commit }, data) => {
  const { data: crawl } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/topic`, getters.apiHeader)
  await commit('loadTopics', crawl)
}

const updateTopics = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/topic`, data, getters.apiHeader)
}

// Articles
const addArticle = async ({ getters }, data) => {
  const { data: id } = await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/article`, data, getters.apiHeader)
  return id
}

const loadArticles = async ({ getters, commit }, data) => {
  const { data: articles } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article${createParamsString(data)}`, getters.apiHeader)
  if (data.straightReturn) return articles
  await commit('loadArticles', articles)
}

const loadArticlesContent = async ({ getters, commit }, data) => {
  const { data: articles } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/articles/content${createParamsString(data)}`, getters.apiHeader)
  if (data.straightReturn) return articles
  await commit('loadArticles', articles)
}

const loadArticlesAdvanced = async ({ getters, commit }, data) => {
  const { data: articles } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/advanced${createParamsString(data)}`, getters.apiHeader)
  if (data.straightReturn) return articles
  await commit('loadArticlesAdvanced', articles)
}

const loadArticle = async ({ getters, commit }, data) => {
  const { data: article } = await axios.get(`${getters.apiUrl}/article/${data.articleId}?key=${data.sharingKey || ''}`, getters.apiHeader)
  await commit('loadArticle', article)
}

const loadArticleRankings = async ({ getters, commit }, data) => {
  const { data: rankings } = await axios.get(`${getters.apiUrl}/article/${data.articleIds}/keyword/rank?key=${data.sharingKey || ''}&url=${data.url || ''}`, getters.apiHeader)
  await commit('addArticleRankings', rankings)
}

const loadArticleUrls = async ({ getters, commit }, data) => {
  const { data: urls } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/url?key=${data.sharingKey || ''}&empty=${data.empty || ''}`, getters.apiHeader)
  await commit('updateArticleUrls', urls)
}

const loadArticleExternalUrls = async ({ getters, commit }, data) => {
  const { data: urls } = await axios.get(`${getters.apiUrl}/article/${data.articleId}/url/external?key=${data.sharingKey || ''}`, getters.apiHeader)
  await commit('updateExternalUrls', urls)
}

const updateArticles = async ({ getters }, data) => {
  await axios.put(`${getters.apiUrl}/contentplan/${data.contentplanId}/article`, data, getters.apiHeader)
}

const loadArticlesByArticle = async ({ getters }, data) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/${data.articleId}`, getters.apiHeader)
  return response
}

const loadPageArticles = async ({ getters, commit }, data) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/page/${data.pageId}/article`, getters.apiHeader)
  commit('loadPageArticles', response)
}

// Misc
const recalculateAlerts = async ({ getters }, data) => {
  await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/alert`, getters.apiHeader)
}

const postCopilot = async ({ getters }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/copilot`, data, getters.apiHeader)
  return response
}

const generateBrief = async ({ getters }, data) => {
  await axios.get(`${getters.apiUrl}/article/${data.articleId}/brief`, data, getters.apiHeader)
}

const generateDraft = async ({ getters }, data) => {
  await axios.get(`${getters.apiUrl}/article/${data.articleId}/draft`, getters.apiHeader)
}

// Misc
const loadScoreAndCommit = async ({ getters, commit }, data) => {
  const { data: score } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/score?articleId=${data.articleId || ''}&keywordId=${data.keywordId || ''}`, getters.apiHeader)
  await commit('updateScore', score)
}

const loadScoreAndReturn = async ({ getters }, data) => {
  const { data: score } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/article/score?articleId=${data.articleId || ''}&keywordId=${data.keywordId || ''}`, getters.apiHeader)
  return score
}

const runUpdateStatemachine = async ({ getters }, data) => {
  await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/update`, getters.apiHeader)
}

const startCrawl = async ({ getters }, data) => {
  await axios.post(`${getters.apiUrl}/contentplan/${data.contentplanId}/crawl`, data, getters.apiHeader)
}

const loadCrawl = async ({ getters, commit }, data) => {
  const { data: crawl } = await axios.get(`${getters.apiUrl}/contentplan/${data.contentplanId}/crawl`, getters.apiHeader)
  await commit('loadCrawl', crawl)
}

const sendSlackNotification = ({ getters }, data) => {
  axios.post(`${getters.apiUrl}/slack`, data, getters.apiHeader)
}

const postUser = async ({ getters }, data) => {
  const { data: userId } = await axios.post(`${getters.apiUrl}/user`, data, getters.apiHeader)
  return userId
}

const getIntegrations = async ({ getters, commit }, data) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/integrations/${data.contentplanId}`, getters.apiHeader)
  await commit('integrations', response)
  return response
}

const postWordpressInteraction = async ({ getters, commit }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/integrations/wordpress`, data, getters.apiHeader)
  await commit('integrations', response)
  return response
}

const postNotionIntegration = async ({ getters, commit }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/integrations/notion`, data, getters.apiHeader)
  await commit('integrations', response)
  return response
}

const getGoogleAnalyticsProperties = async ({ getters }) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/integrations/google-analytics-properties`, getters.apiHeader)
  return response
}

const saveGoogleAnalyticsProperty = async ({ getters }, data) => {
  const { data: response } = await axios.post(`${getters.apiUrl}/integrations/google-analytics-property`, data, getters.apiHeader)
  return response
}

const getGoogleAnalyticsData = async ({ getters, commit }, data) => {
  try {
    const { data: response } = await axios.post(`${getters.apiUrl}/integrations/google-analytics-data`, data, getters.apiHeader)
    return response
  } catch (error) {
    const text = error?.response?.data?.message ?? error.message
    setSnackbar({ commit }, { open: true, text, type: 'error' })
    throw error
  }
}

const getSearchConsoleProperties = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: `integrations/search-console/properties?workspaceId=${data.workspaceId}`,
    method: 'get',
    displaySnackbar: false
  })
  return response
}

const postSearchConsoleIntegration = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: 'integrations/search-console',
    method: 'post',
    data
  })
  return response
}

const getLoginUrl = async ({ getters }) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/integrations/cognito/login`, getters.apiHeader)
  return response
}

const getUserCount = async ({ getters }) => {
  const { data: response } = await axios.get(`${getters.apiUrl}/user-count`, getters.apiHeader)
  return response
}

const joinWaitlist = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: 'join-waitlist',
    method: 'put',
    data
  })
  return response
}

const verifyEmail = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: 'verify-email',
    method: 'post',
    data
  })
  return response
}

const createStripeCheckoutSession = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: 'integrations/stripe/checkout-session',
    method: 'post',
    data
  })
  return response
}

const getStripeLink = async ({ getters, commit }, data) => {
  const response = await makeAxiosRequest({
    getters,
    commit,
    urlSlug: `integrations/stripe/portal-link?workspaceId=${data.workspaceId}`,
    method: 'get'
  })
  return response
}

const initiateCreateContent = async ({ getters }, data) => {
  await axios.post(`${getters.apiUrl}/statemachine/create-content`, data, getters.apiHeader)
}

export default {
  // Internal links
  loadInternalLinksByArticle,
  loadInternalLinksByContentplan,
  // Free Clusterer
  startClusterer,
  getClustererStatus,
  clustererSendEmail,
  // Workspaces
  loadWorkspaces,
  updateWorkspace,
  // Contentplans
  loadContentplans,
  loadContentplan,
  updateContentplan,
  createContentplan,
  runUpdateStatemachine,
  startCrawl,
  loadCrawl,
  recalculateAlerts,
  resetContentplan,
  // Keywords
  loadContentplanKeywords,
  loadPageKeywords,
  getKeyword,
  getMostPopularKeywords,
  uploadKeywords,
  getRelatedKeywords,
  getRelatedKeywordsNlp,
  queueKeywords,
  queueAllKeywords,
  updateKeywords,
  excludeKeywords,
  moveKeywords,
  getKeywordsByKeyword,
  processKeywords,
  // Pages
  getPage,
  getPageFromS3,
  updatePages,
  analyzeLandingPage,
  loadPagesByContentplan,
  loadPagesByArticle,
  loadPageArticles,
  loadPagesByKeywords,
  // Topics
  addTopic,
  updateTopics,
  loadTopics,
  // Articles
  addArticle,
  loadArticles,
  loadArticlesContent,
  loadArticlesAdvanced,
  loadArticle,
  loadArticleKeywords,
  loadArticleRankings,
  loadArticleUrls,
  loadArticleExternalUrls,
  getRelatedBySerpOverlap,
  loadScoreAndCommit,
  loadScoreAndReturn,
  updateArticles,
  loadArticlesByArticle,
  // GSC integration
  getSearchConsoleProperties,
  postSearchConsoleIntegration,
  getGSCDataAndReturn,
  getGSCDataByDate,
  getGSCDataByQuery,
  getGSCDataByPage,
  // Copilot
  postCopilot,
  generateBrief,
  generateDraft,
  // Integrations
  getIntegrations,
  postWordpressInteraction,
  postNotionIntegration,
  getGoogleAnalyticsProperties,
  saveGoogleAnalyticsProperty,
  getGoogleAnalyticsData,
  setSnackbar,
  getLoginUrl,
  createStripeCheckoutSession,
  getStripeLink,
  // Misc
  sendSlackNotification,
  postHierarchicalClustering,
  getUserCount,
  joinWaitlist,
  postUser,
  verifyEmail,
  initiateCreateContent
}
